// adopted from http://stackoverflow.com/a/32624592/1798418
module.exports = function($el) {

  var shouldNotScroll = function(el, delta) {
    return  (
      (el.scrollTop === 0 && delta > 0) ||
      (el.scrollTop === (el.scrollHeight - $(el).innerHeight()) && delta < 0)
    );
  };

  var getCurrentYs = function(e) {
    return Array.prototype.slice.call(e.originalEvent.touches).map(function(touch){
      return touch.clientY;
    });
  };

  var lastY;
  var disableScrollAtEdgeMobileStart = function (e){
    lastY = getCurrentYs(e);
  };

  var disableScrollAtEdgeMobileMove = function (e){
    var currentY = getCurrentYs(e);
    var delta = currentY.map(function(y, id){
      return y - lastY[id];
    }).reduce(function(a, b) {
      return a + b
    }, 0);
    this.scrollTop += delta * -1;
    lastY = currentY;
    e.preventDefault();
  };

  var disableScrollAtEdge = function(e, d) {
    if(shouldNotScroll(this, d)) {
      e.preventDefault();
    }
  };

  return {
    bind: function(){
      $el.bind('mousewheel', disableScrollAtEdge);
      $el.bind('touchstart', disableScrollAtEdgeMobileStart);
      $el.bind('touchmove', disableScrollAtEdgeMobileMove);
    },
    unbind: function(){
      $el.unbind('mousewheel', disableScrollAtEdge);
      $el.unbind('touchstart', disableScrollAtEdgeMobileStart);
      $el.unbind('touchmove', disableScrollAtEdgeMobileMove);
    }
  };
};
