module.exports = function($els, animateScrollTo) {
  $els.click(function() {
    var target, currentScroll, targetScroll, deltaScroll;
    if (location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") && location.hostname === this.hostname) {
      target = $(this.hash);
      target = (target.length ? target : $("[name=" + this.hash.slice(1) + "]"));
      if (target.length) {
        offsetEl = target.data('offsetElement');
        targetScroll = (offsetEl ? offsetEl : target).offset().top;
        currentScroll = $(window).scrollTop();
        deltaScroll = Math.abs(targetScroll - currentScroll);
        animateScrollTo(
          Math.ceil(targetScroll),
          Math.min(deltaScroll, 1000),
          function() {
            // window.location.hash = target.attr('id');
          }
        );
        return false
      }
    }
  });
};
