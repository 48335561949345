module.exports = function($form) {
  $form.each(function() {
    var $this = $(this);
    var $cSubject = $this.find('.js-contactSubject')
    var $cText = $this.find('.js-contactText')
    var $cSend = $this.find('.js-contactSend')
    var update = function(){
      var subject = $cSubject.val()
      var text = $cText.val()
      $cSend.attr("href", "mailto:contact@wearede.com?subject="+  encodeURIComponent(subject) + "&body=" + encodeURIComponent(text))
    }
    $cSubject.on('change', update)
    $cText.on('change', update)
    encodeURIComponent
  })
}
