module.exports = function($page) {
  var pageOptions = {
    extractOpts: function($el, into){
      into = into || {};
      into.theme =$el.data('theme');
      into.name =$el.attr('id');
      into.headerMode =$el.data('header-mode');
      into.logoMode =$el.data('logo-mode');
      return into;
    },
    set: function(opts, $on) {
      Object.keys(pageOptions.dataMap).map(function(key){
        if (pageOptions.active[key] != opts[key]) {
          $on.removeClass(pageOptions.tpl[key].replace('%s', pageOptions.active[key]));
          $on.addClass(pageOptions.tpl[key].replace('%s', opts[key]));
          $on.data('active-' + pageOptions.dataMap[key], opts[key]);
          pageOptions.active[key] = opts[key];
        }
      })
    },
    tpl: {
      theme: $page.data('tpl-theme'),
        name: $page.data('tpl-name'),
        headerMode: $page.data('tpl-header-mode'),
        logoMode: $page.data('tpl-logo-mode'),
    },
    dataMap: {
      theme:'theme',
        name:'name',
        headerMode:'header-mode',
        logoMode:'logo-mode',
    },
    active: {
      theme: $page.data('active-theme'),
        name: $page.data('active-name'),
        headerMode: $page.data('active-header-mode'),
        logoMode: $page.data('active-logo-mode'),
    },
  };

  return pageOptions;
};
