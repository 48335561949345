module.exports = function($player){
  $player.each(function(){
    $(this).magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          vimeo: {
            index: 'vimeo.com/',
            id: '/',
            src: '//player.vimeo.com/video/%id%'
          },
        }
      }
    })
  })
};
