var currentScripts = document.getElementsByTagName('script');
var currentScript = currentScripts[currentScripts.length - 1];
var currentScriptRoot = currentScript.src.split('/').slice(0,-1).concat(['']).join('/')
var DeLngLat = [44.758477,41.715025]//Lng(x) Lat(y)
mapboxgl.accessToken = 'pk.eyJ1Ijoic2FuZHJvZHoiLCJhIjoiY2lsZmVqc3l2MDAzbHZqa3FxZ3JzZm1tdCJ9.Ewoi1kj0JVsIY5wFuGFLYg';
function onMapStyleLoad (e) {
  var map = e.target;
  map.addSource("markers", {
    "type": "geojson",
    "data": {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": DeLngLat
        },
        "properties": {
          "marker-symbol": "de_marker"
        }
      }]
    }
  });

  map.addLayer({
    "id": "markers",
    "type": "symbol",
    "source": "markers",
    "layout": {
      "icon-image": "{marker-symbol}",
    }
  });
};

function createMap(mapNode, mapStyle, options){
  var defaultOptions = {
    attributionControl: false,
    container: mapNode,
    style: $.extend({}, mapStyle, {
      sprite: currentScriptRoot+'assets/map/map_sprite',
    }), // 'mapbox://styles/sandrodz/cilfe7ivi003vbbm5woex9iwk',
    center: DeLngLat,
    zoom: $(mapNode).data('map-zoom')
  };
  var map = new mapboxgl.Map($.extend({}, defaultOptions, options || {}));

  map.on('style.load', onMapStyleLoad);

  return map;
}

function initDefaultMap($map, mapStyle) {
  $map.each(function(){
    createMap(this, mapStyle);
  });
}

function initHomeMap($map, mapStyle){
  $map.each(function(){
    var bounds = function(width){
      var scalor = width < 1000 ? width/1000 : 1 ;
      var ne = [50, 40+(scalor*10)]; // top right
      var sw = [30, 30]; // bottom left
      return new mapboxgl.LngLatBounds(sw, ne);
    };

    var map = createMap(this, mapStyle, {
      center: [ 25, 32 ]
    });

    var makeDimentions = function(){
      return {
        width: $(window).width(),
        height: $(window).height()
      };
    };
    var dimentions;
    $(window).resize(_.throttle(function(){
      var currentDimentions = makeDimentions();
      if (
        dimentions == null ||
        dimentions.width != currentDimentions.width ||
        dimentions.height != currentDimentions.height
      ) {
        dimentions = currentDimentions;
        map.fitBounds(bounds($(window).width()));
      }
    }, 500)).resize();


  })
}

var mapStyle = null;
var loadStyle = function ($map, then) {
  if ($map.length == 0) {
    return;
  }

  if (mapStyle == null) {
    $.getJSON(currentScriptRoot+'assets/map/map.json')
      .done(function(style){
        mapStyle = style;
        then($map,style);
      })
      .fail(function() {
        console.error('loading of map.json failed',arguments);
      });
  } else {
    then($map, mapStyle);
  }
};

module.exports.default = function($map) {
  loadStyle($map, initDefaultMap);
};

module.exports.home = function($map) {
  loadStyle($map, initHomeMap);
};
