require('./lib/requestAnimationFrame');
var turbo = require('./turbo');
var stick = require('./sticky');
var slider = require('./slider');
var share = require('./share');
var pullToLoad = require('./pullToLoad');
var scroller = require('./scroller');
var makeScrollTo = require('./scroll');
var player = require('./player');
var filter = require('./filter');
var pageSlide = require('./page');
var makePageOptions = require('./pageOptions');
var search = require('./search');
var hoverDisable = require('./hoverDisable');
var navigation = require('./navigation');
var contact = require('./contact');
var map = require('./map');

turbo(function() {
  var animateScrollTo = makeScrollTo($('html, body'));

  stick($('.js-sticky'));
  slider($('.js-slider'));
  share($('.js-share'));
  scroller($("a[href*=#]:not([href=#])"), animateScrollTo);
  player($('.js-player-play'));
  filter($('.js-Filter'));
  pullToLoad($('.js-LoadMore'), turbo.visit)
  contact($('.js-contact'));

  var $page = $('.js-Page');
  if ($page.is('.js-hover-on-scroll-disabled')) {
    hoverDisable($('body'), 350, 'isHoverDisabled');
  }

  var pageOptions = makePageOptions($page);
  pageSlide($page, pageOptions, animateScrollTo);
  search($page, pageOptions);
  navigation($page);
  map.home($('.js-homeMap'));
  map.default($('.js-defaultMap'));
});
