var callNext = require('./lib/callNext');
var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

var getSceneOffsetTop = function($el) {
  var $offsetElement = $el.data('offsetElement');
  if ($offsetElement == null) {
    var $parent = $el.parent('.js-pinSpacer');
    $offsetElement = ($parent.length == 0) ? $el : $parent;
    $el.data('offsetElement', $offsetElement);
  }
  return $offsetElement.offset().top
};

const onPageLeave = (f) => {
  $(document).one('turbolinks:before-visit', () => {
    f()
  })
}

const bindListener = ($node, eventName, listerner) => {
  $node.on(eventName, listerner)
  onPageLeave(() => {
    $node.off(eventName, listerner)
  })
  return $node
}
module.exports = function($page, pageOptions, animateScrollTo) {
  $page.each(function(){
    var $page = $(this);
    var isFixed = $page.hasClass('isFixed');
    var $slogans = $page.find('.js-slogan');
    var $window = $(window);
    var windowScrollTop = window.pageYOffset;
    var controller = new ScrollMagic.Controller({
      // addIndicators: true,
      globalSceneOptions: {
        triggerHook: 'onLeave',
        reverse: true,
      }
    });

    onPageLeave(() => {
      controller.destroy(true)
    })

    controller.scrollPos(function () {
      windowScrollTop = window.pageYOffset
      if(window.innerWidth >= 720){
        return windowScrollTop;
      } else {
        return 0;
      }
    });
    controller.scrollTo(function (newpos) {
      var delta = Math.abs(windowScrollTop - newpos);
      var duration = Math.max(400, Math.min(delta, 1000));
      animateScrollTo(newpos, duration);
  	})


    var sections = [];
    $page.find('.js-Page-content .js-slideSection').each(function(){
      sections.push(pageOptions.extractOpts($(this), {
        el: this,
        $el: $(this),
      }));
    });
    var updatePageSettings = function(){
      if ($('body').hasClass('isSearchOpen') || $('html').hasClass('isTurboTransitioning')) {
        return
      }
      var scrollTop = windowScrollTop;
      var activeSection = sections[0];
      for (var i = 0; i < sections.length; i++) {
        var section = sections[i];
        var offsetTop = Math.floor(getSceneOffsetTop(section.$el));
        if(offsetTop <= scrollTop){
          activeSection = section;
        } else {
          break;
        }
      }
      pageOptions.set(activeSection, $page);
    };
    bindListener($window, 'scroll', _.throttle(updatePageSettings, 100));
    bindListener($window, 'resize', _.throttle(updatePageSettings, 100));



    var scenes = [];
    $page.find('.js-Page-content .js-slideSection:not(:last-child)').each(function() {
      var $this = $(this);
      scenes.push({
        el: this,
        $el: $this,
        slogan: $this.data('slogan'),
        content: $this.find($this.data('content')),
      })
    });
    if (scenes.length) {
      $slogans.hide();
    }

    var activeAnimation = null;
    function sectionAnimationScroller(scene) {
      return function(){
        if (activeAnimation.progress == 1 || activeAnimation.progress == 0) {
          return;
        }
        var direction = activeAnimation.progress - activeAnimation.prevProgress;
        var shouldScrollToNext = (
          direction > 0 && activeAnimation.progress > 0.2 ||
          direction < 0 && activeAnimation.progress > 0.8 ||
          direction == 0 && activeAnimation.progress > 0.5
        );

        if (shouldScrollToNext) {
          controller.scrollTo(getSceneOffsetTop(scene.$el) + scene.$el.height());
        } else {
          controller.scrollTo(Math.ceil(scene.scene.scrollOffset()));
        }
      }
    }
    scenes.forEach(function(scene,id){
      var nextScene = scenes[id + 1];
      var $currentSlogan = $page.find('.js-slogan-'+id);
      var $nextSlogan = $page.find('.js-slogan-'+id+'-next');
      var onTweenUpdate = callNext(function() {
        $slogans.hide();
        $currentSlogan.show();
        $nextSlogan.show();
      })
      var sectionTween = TweenMax.fromTo( scene.content, 1, {
        autoAlpha: 1,
      }, {
        autoAlpha: 0,
        onUpdate: _.throttle(onTweenUpdate, 50),
      });
      var timeline = new TimelineLite()
        .add(sectionTween, 0);

      if (isFixed) {
        var currentSloganTween = TweenMax.fromTo( $currentSlogan, 0.6, {
          autoAlpha: 1,
          yPercent: 0,
        },{
          autoAlpha: 0,
          yPercent: -150,
          ease: Linear.easeIn,
        });

        var nextSloganTween = TweenMax.fromTo( $nextSlogan, 1, {
          autoAlpha: 0,
          yPercent: 100,
        }, {
          autoAlpha: 1,
          yPercent: 0,
          ease: Linear.easeIn,
        });

        timeline
          .add(nextSloganTween, 0)
          .add(currentSloganTween, 0);
      }

      scene.scene = new ScrollMagic.Scene({
        triggerElement: scene.el,
      })
      .setTween(timeline)
      .addTo(controller);

      if (isChrome) {
        scene.scene = scene.scene
          .setPin(scene.el, {
            pushFollowers:false,
            spacerClass:'js-pinSpacer'
          })
          .addIndicators({
            colorStart: 'hsla(132, 100%, 50%, 0.5)',
            colorEnd: 'hsla(132, 100%, 50%, 0.5)',
            colorTrigger : 'hsla(132, 100%, 50%, 1)',
            name: scene.slogan,
          });
      }


      var sceneOffsetTop = getSceneOffsetTop(scene.$el)
      var sceneHeight = scene.$el.height()
      if(activeAnimation == null  && sceneOffsetTop <= windowScrollTop && windowScrollTop < sceneOffsetTop + sceneHeight){
        onTweenUpdate();
        var activeAnimationProgress = (windowScrollTop - sceneOffsetTop)/sceneHeight;
        activeAnimation = {
          timeline: timeline,
          scene: scene.scene,
          progress: activeAnimationProgress,
          prevProgress: activeAnimationProgress,
          timeout: setTimeout(callNext(sectionAnimationScroller(scene))),
        };
      }

      [
        {name:'onComplete', isComplete:false},
        {name:'onReverseComplete', isComplete:false},
        {name:'onStart', isComplete:true}
      ].forEach(function(eventOpts){
        timeline.eventCallback(eventOpts.name, function() {
          window.scrollAnimatedAt = eventOpts.isComplete ? 0 : new Date().getTime();
          activeAnimation && clearTimeout(activeAnimation.timeout);
        });
      });

      timeline.eventCallback('onUpdate', function(){
        window.scrollAnimatedAt = new Date().getTime();
        var currentAnimation = {
          timeline: timeline,
          scene: scene.scene,
          progress: timeline.progress(),
          prevProgress: timeline.progress(),
          timeout: setTimeout(callNext(sectionAnimationScroller(scene)), 300),
        };

        if (activeAnimation && activeAnimation.timeline === timeline) {
          currentAnimation.prevProgress = activeAnimation.progress;
        }

        activeAnimation && clearTimeout(activeAnimation.timeout);
        activeAnimation = currentAnimation;
      });

    });

    var $anchors = $page.find('.js-Pagination .js-Pagination-anchor');
    var updatePagination = function(){
      var id;
      for (var i = 0; i < sections.length; i++) {
        var $section = sections[i].$el;
        var section = sections[i].el;
        var elTop = Math.floor(getSceneOffsetTop($section))
        var elHeight = Math.floor($section.height());
        var scrollTop = windowScrollTop;
        if(scrollTop >= elTop && scrollTop < elTop + elHeight){
          id = $section.attr('id');
          break;
        }
      }
      if (id) {
        $anchors.removeClass('isActive');
        $anchors.filter('[href="#' + id + '"]').addClass('isActive');
      }
    }
    bindListener($window, 'scroll', _.throttle(updatePagination, 200));
    bindListener($window, 'resize', _.throttle(updatePagination, 200)).resize();
    bindListener($window, 'resize', _.throttle(function(){
      scenes.forEach(function(scene,id) {
        scene.scene.offset(Math.max(0, scene.el.offsetHeight - window.innerHeight));
        scene.scene.duration(Math.max(0, Math.min(window.innerHeight, scene.el.offsetHeight) - 1 ));
      });
    }, 100))
    $window.resize();
    // disable on transition `start` end enable on `end`, so that we don't get pined scenes stack off the screan
    bindListener($(document),'page-transition:visit', () => controller.enabled(false))
    bindListener($(document),'page-transition:out-start', () => controller.enabled(false))
    bindListener($(document),'page-transition:in-start', () => controller.enabled(false))
    bindListener($(document),'page-transition:in-end', () => controller.enabled(true))
  })

};
