const distanceToRefresh = 70
module.exports = function($dragger, visit){
  $html = $('html')
  $dragger.each(function(){
    const $this = $(this)
    const href = $this.data('href')
    const $page = $this.parents('.js-Page')
    const $arrow = $page.find('.js-LoadMore-arrow')
    const $body = $('body')
    const h = new Hammer(this)
    const readyLength = 100

    /*
     * we subtrack aproximate height of pull to load element, then divide
     * height in therds so that user needs to drag around first therd of page
     */
    const resistance = () => Math.max(1.1, (window.innerHeight - 100) / 3 / readyLength)

    const Pan = {
      enabled: false,
      distance: 0,
      isReady() {
        return Math.abs(this.distance) > readyLength
      },

      renderStep() {
        $page.css('transform', `translateY(${this.distance}px)`)
        $('.js-Page-navigation').css('top', `${$page.height() - window.innerHeight}px`)
        if (this.isReady()) {
          $arrow.addClass('isReady')
        } else {
          $arrow.removeClass('isReady')
        }
      },

      panstart(e) {
        this.enabled = $(window).scrollTop() + window.innerHeight == document.body.clientHeight // is not at the edge of page
          && !$body.hasClass('isMenuOpen') // menu is open
          && !$page.hasClass('isResetingFromPull') // is reseting
        if (this.enabled) {
          e.preventDefault()
          $page.addClass('u-noneInteractive')
          $html.addClass('isPulling')
        }
      },

      pandown(e) {
        if (!this.enabled || e.deltaY > 0) return
        e.preventDefault()
        this.distance = e.deltaY / resistance()
        this.renderStep()
      },

      panup(e) {
        if (!this.enabled) return
        e.preventDefault()
        this.distance = e.deltaY / resistance()
        this.renderStep()
      },

      panend(e) {
        e.preventDefault()
        const isReady = this.isReady()
        $page.removeClass('u-noneInteractive')
        $html.removeClass('isPulling')
        $page.addClass('isResetingFromPull')
        $page.css('transform', `translateY(0)`)
        // https://jonsuh.com/blog/detect-the-end-of-css-animations-and-transitions-with-javascript/
        $page.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
          $page.removeClass('isResetingFromPull')
          $page.css('transform', "")
          $('.js-Page-navigation').css('top', "")
          if (isReady) {
            visit(href, 'bounceOutUp', 'bounceInUp')
          }
        })
        this.distance = 0
        this.enabled = false
      }
    }

    h.get('pan').set({ direction: Hammer.DIRECTION_UP })
    h.on('panstart', Pan.panstart.bind(Pan))
    h.on('pandown', Pan.pandown.bind(Pan))
    h.on('panup', Pan.panup.bind(Pan))
    h.on('panend', Pan.panend.bind(Pan))

  })
}
