
module.exports = function($body, duration, disableClass) {
  var timer
  window.addEventListener('scroll', function() {
    clearTimeout(timer);
    if(!$body.hasClass(disableClass)) {
      $body.addClass(disableClass)
    }

    timer = setTimeout(function(){
      $body.removeClass(disableClass)
    }, duration);
  }, false);
}
