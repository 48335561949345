var to_rfc3986 = function(str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
            return "%" + c.charCodeAt(0).toString(16);
        });
    },
    is_encoded = function(str) {
        str = to_rfc3986(str);
        return decodeURIComponent(str) !== str;
    },
    encode = function(str) {
        if (typeof str === "undefined" || is_encoded(str)) {
            return str;
        } else {
            return to_rfc3986(str);
        }
    };

module.exports = function(url, params) {
    // compiled from coffeeScript
    // @url: https://github.com/carrot/share-button/blob/cd06990d9da985989ae72c118b6d03b921aa2243/src/share_utils.coffee#L77-L88
    var k, popup, qs, v;
    if (params == null) {
        params = {};
    }
    popup = {
        width: 500,
        height: 350
    };
    popup.top = (screen.height / 2) - (popup.height / 2);
    popup.left = (screen.width / 2) - (popup.width / 2);
    qs = ((function() {
        var k, results = [];
        for (k in params) {
            v = params[k];
            results.push(k + "=" + (encode(v)));
        }
        return results;
    }).call(this)).join('&');
    if (qs) {
        qs = "?" + qs;
    }
    return window.open(url + qs, 'targetWindow', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,left=" + popup.left + ",top=" + popup.top + ",width=" + popup.width + ",height=" + popup.height);
};