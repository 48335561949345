var popup = require('./lib/popup.js');

var targets = {
  facebook: function() {
    popup('https://www.facebook.com/sharer/sharer.php', {
      u: window.location.href
    });
  },
  twitter: function(){
    popup('https://twitter.com/intent/tweet', {
      text: $(document).find("title").text(),
      url: window.location.href
    });
  },
  linkedin: function(){
    popup('https://www.linkedin.com/cws/share', {
      url: window.location.href
    });
  }
};

module.exports = function($els) {
  $els.click(function(){
    var target = $(this).data('share-target');
    if (targets[target]) {
      targets[target]()
    }
    return false;
  });
};
