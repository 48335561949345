// [1,2] trigger the event on nex tick so that all turbolinks event handlers are executed in current tick

const $html = $('html')
const $document = $(document)
$document.on('turbolinks:before-render', function(e) {
  const  $bodyOld =  $('body')
  const aIn = $bodyOld.data('animation-in')
  $bodyOld.removeClass($bodyOld.data('remove-class'))
  const $body = $(e.originalEvent.data.newBody)
  if (aIn == null) {
    // it's history go back or forward so we don't need to animate
    return
  }
  const bodyAnimationClass = 'animated u-noneInteractive ' + aIn
  $body.addClass(bodyAnimationClass)
  setTimeout(() => { $document.trigger('page-transition:in-start') }, 0) // [1]
  $body.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
    $body.removeClass(bodyAnimationClass);
    $html.removeClass('isLoading');
    $document.trigger('page-transition:in-end')
  });
});

$document.on('turbolinks:click', function() {
  return false;
});

const visit = (href, animationOut = 'bounceOutLeft', animationIn = 'slideInRight') => {
  $html.addClass('isTurboTransitioning')
  $(document).trigger('page-transition:visit')
  const $body = $('body')
  const bodyAnimationClass = 'animated u-noneInteractive ' + animationOut
  const scrollTop = $(window).scrollTop()
  $(window).scrollTop(0)
  $('.js-Page').css('transform', `translateY(-${scrollTop}px)`)
  $('.js-Page-navigation').css('top', `${scrollTop}px`)
  $body.addClass(bodyAnimationClass)
  $body.data('remove-class', bodyAnimationClass);
  $body.data('animation-in', animationIn);
  setTimeout(() => { $document.trigger('page-transition:out-start') }, 0) // [2]
  $body.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
    $html.removeClass('isTurboTransitioning')
    $document.trigger('page-transition:out-end')
    Turbolinks.visit(href)
  })
  $html.addClass('isLoading');
}

$document.on('turbolinks:load', function() {
  fbq('init', '448295378897968');
  fbq('track', 'PageView');
  gtag('config', 'UA-56029753-1', {'page_path': window.location.pathname});
  if (! $('body').data('no-turbolink')) {
    $('a:not([href^="#"])').on('click', function(e) {
      const $this = $(this)
      if (! $this.data('no-turbolink')) {
        e.preventDefault();
        visit($this.attr('href'), $this.data('animation-out'), $this.data('animation-in'))
      }
    });
  }
});


module.exports = function(f) {
  $document.on('turbolinks:load', f)
}

module.exports.visit = visit
window.visit = visit
