var freezeOuterScroll = require('./lib/freezeOuterScroll');

module.exports = function($page) {
  var isFixed = $page.hasClass('isFixed');
  var $pageMenu = $('.js-Page-menu');
  var $pageNavigation = $('.js-Page-navigation');
  var $pageLogo = $('.js-Page-logo');
  var $pageAsideEnd = $('.js-Page-aside-end');
  var $contentBody = $('.js-Content-content');
  var $contentAsideEnd = $('.js-Content-aside-end');
  var $sidebarBackgroundNavigation = $('.js-sidebarBackgroundNavigation');
  var $sidebarBackgroundSearch = $('.js-sidebarBackgroundSearch');
  var $openBtn = $('.js-openMenu');
  var $goBackBtn = $('.js-goBack');
  var $closeBtn = $('.js-closeMenu');
  var disableScroll = freezeOuterScroll($pageNavigation);
  var $pushElements = $contentBody
    .add($contentAsideEnd)
    .add($pageAsideEnd);

  var MenuMaxPushWidth = 350;
  var offset = Math.min(MenuMaxPushWidth, window.innerWidth);
  var timeline = new TimelineLite()
    .add(
      TweenMax.fromTo($pageLogo, 0.3,  {
        autoAlpha: 1,
      }, {
        autoAlpha: 0,
      })
    ).add(
      TweenMax.fromTo($pushElements, 0.3, {
        right: 0,
      }, {
        right: offset * (-1),
        ease: Power4.easeInOut,
      }),
      0
    ).add(
      TweenMax.fromTo($sidebarBackgroundNavigation, 0.3, {
        autoAlpha: 0,
        x:-100,
      }, {
        autoAlpha: 1,
        x: 0,
      }),
      0
    ).add(
      TweenMax.fromTo($pageMenu, 0.3, {
        autoAlpha: 0,
        display: 'none',
        x: offset * (-1),
      }, {
        autoAlpha: 1,
        x: 0,
        display: 'block',
        ease: Power4.easeInOut,
      }),
      0.15
    );
  timeline.pause();
  var sidebarBackgroundSearchVisible = false;
  $openBtn.click(function(){
    sidebarBackgroundSearchVisible = $sidebarBackgroundSearch.is(':visible')
    if(sidebarBackgroundSearchVisible){
      $sidebarBackgroundSearch.data('tween').reverse()
    }
    if(new Date().getTime() - window.scrollAnimatedAt <= 300){
      return false;
    }
    $openBtn.hide();
    $closeBtn.show();
    timeline.play(0);
    isFixed && disableScroll.bind();
    $('body').addClass('isMenuOpen');
  })
  $closeBtn.click(function(){
    if(sidebarBackgroundSearchVisible){
      $sidebarBackgroundSearch.data('tween').play()
    }
    $('body').removeClass('isMenuOpen');
    isFixed && disableScroll.unbind();
    $closeBtn.hide();
    $openBtn.show();
    timeline.reverse();
  })
  $goBackBtn.click(function(){
    history.back()
  });
  // .click();
};
