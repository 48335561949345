module.exports = function($filter) {
  $filter.each(function() {
    var $this = $(this);
    var filterAttr = $this.attr('data-filter-attr');
    var filterSeparator = $this.attr('data-filter-separator');
    var filterItemInactiveclass = $this.attr('data-filter-item-inactiveclass');
    var filterButtonActiveclass = $this.attr('data-filter-button-activeclass');
    var $buttons = $this.find('.js-FilterButton');
    var $items = $this.find('.js-FilterItem');

    var itemsByFilter = {};

    $items.each(function() {
      var $item = $(this);
      var filterTarget = $item.attr(filterAttr);
      filterTarget.split(filterSeparator).forEach(function(name) {
        if (itemsByFilter[name] == null) {
          itemsByFilter[name] = []
        }
        itemsByFilter[name].push($item)
      })
    });


    var currentFilter = null;
    $buttons.on('click', function() {
      var $this = $(this);
      var filterBy = $(this).attr('data-filter-by');
      if (filterBy == currentFilter) {
        return
      }

      $buttons.removeClass(filterButtonActiveclass);
      $this.addClass(filterButtonActiveclass);

      $items.removeClass(filterItemInactiveclass);
      $items.each(function(){
        var $this = $(this);
        if ($this.attr(filterAttr).split(filterSeparator).indexOf(filterBy) == -1) {
          $this.addClass(filterItemInactiveclass)
        }
      });
      currentFilter = filterBy
    })
  })
};
