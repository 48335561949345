var freezeOuterScroll = require('./lib/freezeOuterScroll');

module.exports = function($page, pageOptions) {
  var $pageSearch = $('.js-Page-search');
  var $openBtn = $('.js-openSearch');
  var $sidebarBackgroundSearch = $('.js-sidebarBackgroundSearch');
  var $closeBtn = $('.js-closeSearch');
  var $input = $('.js-Search-input');
  var $resultCount = $('.js-Search-resultCount');
  var $listAnchors = $('.js-Search-listAnchor');
  var disableScroll = freezeOuterScroll($pageSearch);
  var searchOpts = pageOptions.extractOpts($pageSearch.find('.js-slideSection'));
  var activeOpts;

  $sidebarBackgroundSearch.data('tween', TweenMax.fromTo($sidebarBackgroundSearch, 0.3, {
    autoAlpha: 0,
  }, {
    autoAlpha: 1,
    ease: Power4.easeInOut,
  }));

  $sidebarBackgroundSearch.data('tween').pause();
  $openBtn.click(function(){
    $sidebarBackgroundSearch.data('tween').play()
    $sidebarBackgroundSearch.show()
    activeOpts = jQuery.extend({}, pageOptions.active);
    pageOptions.set(searchOpts, $page);
    if(new Date().getTime() - window.scrollAnimatedAt <= 300){
      return false;
    }
    disableScroll.bind();
    $('body').addClass('isSearchOpen');
  })
  $closeBtn.click(function(){
    $sidebarBackgroundSearch.hide()
    $sidebarBackgroundSearch.data('tween').reverse()
    pageOptions.set(activeOpts, $page);
    $('body').removeClass('isSearchOpen');
    disableScroll.unbind();
  })

  var linkNames = [];
  var cleenUpText = function(str){
    return str.toLowerCase().replace(/[^a-zA-Z]/g, "");
  };
  for (var i = 0; i < $listAnchors.length; i++) {
    linkNames.push({
      name: cleenUpText($($listAnchors[i]).text().trim()),
      el: $listAnchors[i],
    })
  }
  $input.on('input', function(e){
    var value = e.target.value.trim();
    var cleanValue = cleenUpText(value);
    var count = 0;
    $listAnchors.removeClass('isActive');
    if(value == ''){
      $resultCount.text(linkNames.length);
      return;
    }
    for (var i = 0; i < linkNames.length; i++) {
      if(cleanValue != '' && linkNames[i].name.indexOf(cleanValue) > -1){
        count++;
        $(linkNames[i].el).addClass('isActive');
      }
    }
    $resultCount.text(count);
  })
};
