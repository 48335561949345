module.exports = function($viewport) {
  // adopted from http://stackoverflow.com/a/10144683/1798418

  var events = "scroll mousedown DOMMouseScroll mousewheel keyup touchmove";

  var stopper = function (e) {
    if ( e.which > 0 || e.type === "mousedown" || e.type === "mousewheel"){
      $viewport.stop().unbind(events, stopper);
    }
  };

  return function(scrollTarget, duration, onComplete) {
    $viewport.bind(events, stopper);
    $viewport.animate({
      scrollTop: scrollTarget
    }, duration, function(){
      $viewport.unbind(events, stopper);
      if(typeof onComplete === 'function'){
        onComplete();
      }
    });
  };
};
